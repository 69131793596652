<script setup lang="ts">
import {
  Navigation as SwiperNavigation,
  FreeMode as SwiperFreeMode,
} from "swiper/modules";
import type { EntryCollection } from "contentful";
import { theme } from "#tailwind-config";
import type { TypeButton } from "~/types/TypeButton";
import {
  isTypeTheme,
  isTypeTopic,
  type TypeCarouselColumn,
  type TypePromoSkeleton,
} from "~/types/contentful";

const props = defineProps<{
  fields: TypeCarouselColumn<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"] & {
    button: TypeButton[];
  };
}>();

const promoIds = computed(() => props.fields.promos.map((promo) => promo?.sys.id));

const { data: promos } = await useLazyFetch<
  EntryCollection<TypePromoSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">
>("/api/promos", {
  query: {
    promoIds,
  },
  transform: useCircularProtect,
});

// repeated promos for carousel loop to work properly
const repeatedPromos = computed(() =>
  promos.value?.items,
);

const buttonPrev = ref<HTMLElement>();
const buttonNext = ref<HTMLElement>();
</script>

<template>
  <div class="relative my-4 md:my-6">
    <UiContainer class="">
      <div
        v-if="fields.displaySectionTitle"
        class="w-full"
      >
        <TextSectionTitle>{{ fields.title }}</TextSectionTitle>
      </div>
    </UiContainer>
    <div class="absolute bottom-[26px] right-[40px] z-[2] flex gap-3">
      <div ref="buttonPrev">
        <NuxtIcon
          name="fairr:button-arrow"
          size="40"
          class="rotate-180 cursor-pointer text-light-royal-blue"
        />
      </div>
      <div ref="buttonNext">
        <NuxtIcon
          name="fairr:button-arrow"
          size="40"
          class="cursor-pointer text-light-royal-blue"
        />
      </div>
    </div>
    <ClientOnly>
      <swiper-container
        :modules="[SwiperNavigation, SwiperFreeMode]"
        :loop="true"
        :centered-slides="false"
        :slides-per-group="1"
        :space-between="0"
        :free-mode="{
          enabled: true,
          sticky: true,
        }"
        :navigation="{
          nextEl: buttonNext,
          prevEl: buttonPrev,
        }"
        :breakpoints="{
          320: {
            slidesPerView: 1,
          },
          420: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 4,
          },
          1920: {
            slidesPerView: 5,
          },
        }"
        class="h-[420px] md:h-auto"
      >
        <swiper-slide
          v-for="(promo, index) in repeatedPromos"
          :key="index"
          class="border-r border-white/20"
        >
          <div class="absolute bottom-[88px] px-4">
            <NuxtLink :to="promo.fields.link ? getUrl(promo.fields.link) : ''">
              <h3 class="mb-3 text-white">
                {{ promo.fields.title }}
              </h3>
            </NuxtLink>

            <div
              v-for="themeOrTopic in promo.fields.themeOrTopic?.filter((themeOrTopic): themeOrTopic is NonNullable<typeof themeOrTopic> => !!themeOrTopic)"
              :key="themeOrTopic?.fields.title"
              class=""
            >
              <div class="mb-2">
                <UiSaveButtonBadge
                  v-if="themeOrTopic"
                  :topic="themeOrTopic"
                  colour="navy"
                  :badge-colour="
                    isTypeTheme<'WITHOUT_UNRESOLVABLE_LINKS', 'en-GB'>(themeOrTopic)
                      ? themeOrTopic.fields.colour
                      : isTypeTopic<'WITHOUT_UNRESOLVABLE_LINKS', 'en-GB'>(themeOrTopic)
                        ? themeOrTopic.fields.theme?.fields.colour
                        : ''
                  "
                />
              </div>
            </div>

            <div
              v-if="promo.fields.followContent"
              class="mt-4"
            >
              <UiSaveButtonIcon
                :entry="promo.fields.followContent"
                type="follow"
                colour="white"
              />
            </div>
          </div>

          <NuxtImg
            v-if="promo.fields.backgroundImage"
            :src="promo.fields.backgroundImage.fields.file?.url"
            :alt="promo.fields.backgroundImage.fields.title"
            :width="511"
            :height="734"
            fit="contain"
            class="w-full"
            loading="lazy"
          />
        </swiper-slide>
      </swiper-container>
    </ClientOnly>
    <div
      class="after:absolute after:bottom-[-12px] after:left-0 after:h-[12px] after:w-full after:content-[''] after:[background-image:linear-gradient(to_bottom,var(--color-white)_0_2px,var(--color-dark-blue-grey-two)_2px_4px,var(--color-white)_4px_6px,var(--color-dark-blue-grey-two)_6px_8px,var(--color-white)_8px_10px,var(--color-dark-blue-grey-two)_10px_12px)] sm:w-full"
      :style="{ '--color-white': theme.colors.white, '--color-dark-blue-grey-two': theme.colors['dark-blue-grey-two'] }"
    />
  </div>
</template>
